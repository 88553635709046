<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">JOBS</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Edit Job</h4>
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Job Title</label>
            <input
              v-model="job.title"
              type="text"
              class="form-control"
              placeholder="Enter Job Title"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Application Deadline</label>
            <input
              v-model="job.deadline"
              type="date"
              class="form-control"
              placeholder="Enter Job Title"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Job Responsibilities</label>
            <div
              v-for="(_resp, i) in job.responsibilities"
              :key="i"
              class="d-flex mt-1"
            >
              <input
                v-model="job.responsibilities[i]"
                type="text"
                class="form-control w-full"
                placeholder="Enter Responsibility"
              />
              <button
                class="btn btn-danger ml-1"
                @click.prevent="removeItem(i, 'responsibilities')"
              >
                &#215;
              </button>
            </div>

            <button
              class="btn btn-block mt-1 btn-info"
              @click.prevent="job.responsibilities.push('')"
            >
              +
            </button>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Job Skills</label>
            <div v-for="(_resp, i) in job.skills" :key="i" class="d-flex mt-1">
              <input
                v-model="job.skills[i]"
                type="text"
                class="form-control w-full"
                placeholder="Enter Responsibility"
              />
              <button
                class="btn btn-danger ml-1"
                @click.prevent="removeItem(i, 'skills')"
              >
                &#215;
              </button>
            </div>

            <button
              class="btn btn-block mt-1 btn-info"
              @click.prevent="job.skills.push('')"
            >
              +
            </button>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Job Qualifications</label>
            <div
              v-for="(_resp, i) in job.qualifications"
              :key="i"
              class="d-flex mt-1"
            >
              <input
                v-model="job.qualifications[i]"
                type="text"
                class="form-control w-full"
                placeholder="Enter Qualification"
              />
              <button
                class="btn btn-danger ml-1"
                @click.prevent="removeItem(i, 'qualifications')"
              >
                &#215;
              </button>
            </div>

            <button
              class="btn btn-block mt-1 btn-info"
              @click.prevent="job.qualifications.push('')"
            >
              +
            </button>
          </div>

          <button @click.prevent="saveJob" class="btn btn-block btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";

export default {
  name: "AddBrand",
  data() {
    return {
      job: {
        title: "",
        responsibilities: [""],
        qualifications: [""],
        skills: [""],
        deadline: "",
      },
    };
  },
  methods: {
    async saveJob() {
      try {
        const res = await axios.patch(
          `/api/v1/jobs/${this.$route.params.id}`,
          this.job,
          {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          }
        );

        if (res.status == 200) {
          this.$router.push("/jobs");
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
    removeItem(index, type) {
      this.job[type] = this.job[type].filter((item, i) => {
        item;
        return i !== index;
      });
    },
    async getJob() {
      try {
        const res = await axios.get(`/api/v1/jobs/${this.$route.params.id}`);

        if (res.status === 200) {
          const data = res.data.job;

          data.deadline = new Date(data.deadline)
            .toISOString()
            .substring(0, 10);

          if (!data.responsibilities) {
            data.responsibilities = [];
          }

          if (!data.qualifications) {
            data.qualifications = [];
          }

          this.job = data;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getJob();
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style>