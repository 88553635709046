<template>
  <!-- Page Wrapper -->
  <div id="wrapper">
    <!-- Sidebar -->
    <ul
      class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <!-- Sidebar - Brand -->
      <router-link
        class="sidebar-brand d-flex align-items-center justify-content-center"
        to="/dashboard"
      >
        <div class="sidebar-brand-icon rotate-n-15">
          <i class="fas fa-laugh-wink"></i>
        </div>
        <div class="sidebar-brand-text mx-3">EXPERCMS</div>
      </router-link>

      <!-- Divider -->
      <hr class="sidebar-divider my-0" />

      <!-- Nav Item - Dashboard -->
      <li class="nav-item">
        <router-link to="/dashboard" class="nav-link cursor-pointer">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link to="/brands" class="nav-link cursor-pointer">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Brands</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link to="/services" class="nav-link cursor-pointer">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Services</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link to="/clients" class="nav-link cursor-pointer">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Clients</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link to="/events" class="nav-link cursor-pointer">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Events</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link to="/newsletter" class="nav-link cursor-pointer">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Newsletter</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link to="/jobs" class="nav-link cursor-pointer">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Jobs</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link to="/home-sliders" class="nav-link cursor-pointer">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Home Sliders</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link to="/banners" class="nav-link cursor-pointer">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Banners</span></router-link
        >
      </li>
    </ul>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <!-- Topbar -->
        <nav
          class="
            navbar navbar-expand navbar-light
            bg-white
            topbar
            mb-4
            static-top
            shadow
          "
        >
          <!-- Sidebar Toggle (Topbar) -->
          <button
            id="sidebarToggleTop"
            class="btn btn-link d-md-none rounded-circle mr-3"
          >
            <i class="fa fa-bars"></i>
          </button>

          <!-- Topbar Navbar -->
          <ul class="navbar-nav ml-auto">
            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
            <li class="nav-item dropdown no-arrow d-sm-none"></li>

            <!-- Nav Item - Alerts -->
            <li class="nav-item dropdown no-arrow mx-1"></li>

            <div class="topbar-divider d-none d-sm-block"></div>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
              <button @click="logout" class="btn btn-primary">Logout</button>
            </li>
          </ul>
        </nav>
        <!-- End of Topbar -->

        <!-- Begin Page Content -->
        <div class="container-fluid">
          <slot></slot>
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- End of Main Content -->

      <!-- Footer -->
      <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright &copy; Experglobal</span>
          </div>
        </div>
      </footer>
      <!-- End of Footer -->
    </div>
    <!-- End of Content Wrapper -->
  </div>
  <!-- End of Page Wrapper -->
</template>

<script>
export default {
  name: "MainLayout",
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style>
</style>